<template>
  <v-dialog
      ref="dialog"
      v-model="modal"
      :return-value.sync="date"
      persistent
      width="290px"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-text-field
          :placeholder="placeholder"
          :rules="rules"
          :value="dateFormatted"
          v-bind="attrs"
          v-on="on"
          readonly
          outlined
          height="32px"
          hide-details
          :disabled="disabled"
          :clearable="clearable"
          @click:clear="date = null"
          @input="$emit('input', date)"
      />
    </template>
    <v-date-picker
        v-model="date"
        :min="min"
        :max="max"
    >
      <v-btn
          text
          color="primary"
          @click="modal = false"
      >
        Cancel
      </v-btn>
      <v-btn
          text
          color="primary"
          @click="clickOk(date)"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>

</template>

<script>
import DateTime from 'luxon/src/datetime.js'

export default {
  // props: ["dateInput", "min", "placeholder", "rules", 'value','defaultToday'],
  props: {
    dateInput: String,
    min: String,
    max: String,
    placeholder: String,
    rules: Array,
    value: String,
    defaultToday: Boolean,
    disabled: Boolean,
    clearable: Boolean
  },
  data: vm => ({
    date: vm.defaultToday ? DateTime.now().toISODate() : vm.parseDate(vm.value),
    dateFormatted: vm.defaultToday ? vm.formatDate(DateTime.now().toISODate()) : vm.formatDate(vm.parseDate(vm.value)),
    modal: false,
  }),

  created() {
    if (this.defaultToday) {
      const [year, month, day] = this.date.split('-')
      this.$emit("input", `${year}/${month}/${day}`)
    }
  },

  watch: {
    value(val) {
      this.date = this.parseDate(val)
    },
    date(val) {
      this.dateFormatted = this.formatDate(val)
    },
  },

  methods: {

    formatDate(date) {
      if (!date) return ''

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate(date) {
      if (!date) return null

      if (date[4] === '/') {
        let [year, month, day] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      } else {
        let [year, month, day] = date.split('-')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      }


    },
    clickOk(date) {
      this.$refs.dialog.save(date)
      if (!date) return null

      const [year, month, day] = date.split('-')
      this.$emit("input", `${year}/${month}/${day}`)
    },
  },
}
</script>

<style>
[class$="--disabled"],
[class*="--disabled "] * {
  color: #000 !important;
}
</style>

<!--<template>-->
<!--  <label>-->
<!--    <input v-on:input="handleInput($event.target.value)" />-->
<!--  </label>-->
<!--</template>-->

<!--<script>-->
<!--export default {-->
<!--  prop: ['value'],-->
<!--  data () {-->
<!--    return {-->
<!--      content: this.value-->
<!--    }-->
<!--  },-->
<!--  methods: {-->
<!--    handleInput (value) {-->
<!--      console.log("cccc")-->
<!--      this.$emit('input', value)-->
<!--    }-->
<!--  }-->
<!--}-->
<!--</script>-->
