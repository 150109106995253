<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        ข้อมูลลูกค้า
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-btn
          depressed
          @click="$router.push({path: '/setting/customer/create'})"
          color="success"
      >เพิ่มข้อมูลลูกค้า
      </v-btn>
      <v-btn @click="showFilter = !showFilter" icon>
        <v-icon>mdi-filter</v-icon>
      </v-btn>
    </v-toolbar>
    <div class="mx-2 box box-2" style="min-height: 100%;">
      <splitpanes style="height: 100%">
        <pane :size="showFilter ? 80 : 100" style="overflow: auto" class="pl-2 pb-4">
          <v-card
              class="rounded-lg elevation-2 mr-2"
              outlined
              tile
          >
            <v-data-table
                :search="search"
                loading-text="Loading... Please wait"
                :headers="header"
                :items="customer_filters"
                :header-props="{ sortIcon: null }"
                @click:row="goCustomerDetail"
            >

            </v-data-table>
          </v-card>
        </pane>
        <pane :size="showFilter ? 20 : 0" class="pb-4">
          <v-card
              class="pa-2 rounded-lg elevation-2"
              outlined
              tile
          >
            <h4 class="font-weight-regular">ค้นหา</h4>
<!--            <v-select-->
<!--                class="rounded mt-3"-->
<!--                :items="header"-->
<!--                outlined-->
<!--                dense-->
<!--                height="32px"-->
<!--                hide-details-->
<!--                placeholder="เลือกข้อมูลที่ต้องการค้นหา"-->
<!--                v-model="optionSearch"-->
<!--            ></v-select>-->
            <v-text-field
                dense
                outlined
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
                class="rounded my-3"
                hide-details
                v-model="search"
            ></v-text-field>
            <h4 class="font-weight-regular my-3">ค้นหาตามวันที่เพิ่มข้อมูลลูกค้า</h4>
            <DatePicker placeholder="วันเริ่มต้น" v-model="start_date" clearable/>
            <v-card elevation="0" height="10px"/>
            <DatePicker placeholder="วันสิ้นสุด" v-model="end_date" clearable/>
            <v-divider class="my-4"/>
          </v-card>
<!--          {{findHeaders}}-->
<!--          <FilterCard @search-object="searchCustomer" :search-option="findHeaders" date/>-->
        </pane>
      </splitpanes>
      <!--      <v-row dense class="box box-2" style="height: 100%;display: flex;flex-direction: row">-->
      <!--        <v-col-->
      <!--            style="height: 100%"-->
      <!--        >-->
      <!--          <v-data-table-->
      <!--              :search="search"-->
      <!--              loading-text="Loading... Please wait"-->
      <!--              :headers="header"-->
      <!--              :items="customers"-->
      <!--              :header-props="{ sortIcon: null }"-->
      <!--              @click:row="goCustomerDetail"-->
      <!--          >-->

      <!--          </v-data-table>-->
      <!--        </v-col>-->
      <!--        <v-expand-x-transition>-->

      <!--          <v-card-->
      <!--              v-show="showFilter"-->
      <!--              class="pa-2 rounded-lg elevation-2 ma-1"-->
      <!--              outlined-->
      <!--              tile-->
      <!--          >-->
      <!--            <h4 class="font-weight-regular">ค้นหา</h4>-->
      <!--            <v-select-->
      <!--                class="rounded mt-3"-->
      <!--                :items="header"-->
      <!--                outlined-->
      <!--                dense-->
      <!--                height="32px"-->
      <!--                hide-details-->
      <!--                placeholder="เลือกข้อมูลที่ต้องการค้นหา"-->
      <!--                v-model="optionSearch"-->
      <!--            ></v-select>-->
      <!--            <v-text-field-->
      <!--                dense-->
      <!--                outlined-->
      <!--                placeholder="กรอกข้อมูลที่ต้องการค้นหา"-->
      <!--                class="rounded mt-3"-->
      <!--                hide-details-->
      <!--                v-model="search"-->
      <!--            ></v-text-field>-->
      <!--            <v-divider class="my-4"/>-->
      <!--          </v-card>-->
      <!--        </v-expand-x-transition>-->
      <!--      </v-row>-->
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {Splitpanes, Pane} from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import FilterCard from "@/components/FilterCard";
import DatePicker from "@/components/DatePicker";

export default {
  name: "CustomerList",
  components: {DatePicker, FilterCard, Splitpanes, Pane},
  data() {
    return {
      customers: [],
      header: [
        {text: 'ชื่อ', value: 'name'},
        {text: 'เบอร์โทร', value: 'tel'},
        {text: 'เครดิต', value: 'credit', align: 'center'},
        {text: 'ประเภท', value: 'type', align: 'center'},
        {text: 'เงินเติม', value: 'prepaid', align: 'center'},
        {text: 'ถูกเพิ่มเมื่อ', value: 'create_date', align: 'left'},
      ],
      optionSearch: '',
      showFilter: false,
      search: '',
      findHeaders: [
        {
          text: 'ชื่อลูกค้า/เบอร์โทร',
          value: 'customer_name'
        },
      ],
      start_date: '',
      end_date: ''
    }
  },
  created() {
    this.getUserData()
  },
  computed: {
    customer_filters() {
      return this.customers.filter((c) => {
        let start = this.start_date
        let end = this.end_date
        if (!this.start_date) {
          start = '0000/00/00'
        }
        if (!this.end_date) {
          end = '9999/12/31'
        }
        return c.create_date.slice(0, 10) >= start && c.create_date.slice(0, 10) <= end
      })
    }
  },
  methods: {
    getUserData(query_text='') {
      axios.get('/api/accounts/customer/'+query_text)
          .then(res => {
            this.customers = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          }).finally(() => {
        this.$store.state.isLoading = false
      })
    },
    goCustomerDetail(row) {
      let routeData = this.$router.resolve({path: `/setting/customer/${row.id}`});
      window.open(routeData.href, '_blank');
      // this.$router.push({path: `/setting/customer/${row.id}`})
    },
    searchCustomer(event) {
      this.search = event.customer_name
      console.log(event)
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: transform .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  /*width: 50%;*/
  transform: translateX(100%);
}

::v-deep .v-input__append-inner {
  margin-top: 4px;
}
</style>
